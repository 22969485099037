<template>
  <div>
    <div v-if="type===1">
      <div
        class="medicine"
        v-for="(i,key) in value"
        :key="key">
          <div
            v-if="data.question.isEdit"
            @click="MedicineClick(i,key,data.frontend_id,data.question.isEdit)"
            class="selected-medicine">
            {{drugStr(i)}}
          </div>
          <div
            class="delete-i"
            v-if="data.question.isEdit"
            @click="clearMedicine(key)">
            删除
          </div>
          <div
            v-if="!data.question.isEdit"
            @click="MedicineClick(i,key,data.frontend_id,data.question.isEdit)"
            :class="['isedit1',{'pad': key!==0}]">
            {{drugStr(i)}}
          </div>
      </div>
      <div v-if="data.isaddMedicine" :class="['medicine',{'iserror':data.error}]">
        <div class="right" @click="MedicineClick(i='',key='',data.frontend_id,data.question.isEdit)">
            <span class="text1">请选择药品</span>
        </div>
        <div class="delete-i" @click="clearMedicine(key='')">删除</div>
      </div>
      <div
        @click="addMedicine()"
        v-if="data.question.isEdit" class="medicine">
        <img
          class="medicine-img"
          src="../../../assets/img/new-img/add-medicine.png"/>
        <div class="addtext">点击添加</div>
      </div>
    </div>
    <div v-if="type===2" :class="['yao',{'line': !data.value || data.value.length===0}]">
        <div
          class="medicine common"
          v-for="(i,key) in data.value"
          :key="key">
          {{drugStr(i)}}
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    data: {
      default: () => {},
      type: Object
    },
    type:{
      default: null,
      type: Number
    },
    value: {
      default: null,
      type: null
    },
  },
  created() {

  },
  methods: {
    MedicineClick (i,key,val,edit) {
      if (!edit) {
        return
      }
      let index = ''
      if(i!=='') {
        index = key
      }
      this.$router.push({path: '/gauge-medicine',query: {
        frontend_id: this.data.frontend_id,
        key: index
      }})
      this.$emit('savePosition')

    },
    clearMedicine (key) {
      if(key !== '') {
        this.data.value = this.data.value.filter((i,index) => {
          return key!==index
        })
      } else {
        this.data.error = ''
        this.data.isaddMedicine = false
      }
    },
    addMedicine () {
      this.data.isaddMedicine = true
    },
    drugStr (val) {
      if (this.data.type === 8) {
        // if (this.data.value && this.data.value.name) {
        const {name, type, pow_label, spec_label, dosage_num, dosage_label, frequency_num, frequency_label, medicine, medicine_unit} = val
        if (type === '1') {
          return `${name}；${pow_label}；${spec_label}；${frequency_num}次/${frequency_label}；每次${dosage_num}${dosage_label};${medicine}${medicine_unit}`
        } else if (type === '2'){
          return `${name}；${dosage_num}${dosage_label}/次；${frequency_num}次/${frequency_label};${medicine}${medicine_unit}`
        }
        // }
      }
      return null
    },
  }
}
</script>
<style>
  .add-html-box{
    & img {
      max-width: 100%;
    }
  }
</style>
<style scoped>
.medicine {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    min-height: 44px;
    border-bottom:1px solid rgba(235, 237, 240, 1);
     &.iserror {
      border:1px solid ;
     }
    & .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content:space-between;
      border-radius:4px;

      & .text1 {
        color: #999;
      }
    }
    & .selected-medicine {
      flex: 1;
    }
  }
  .medicine-img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.isedit1 {
  flex: 1;
  border: none;
  padding: 0px 9px 16px 9px;
  border-bottom:1px dotted #999999;
  color:rgba(51,51,51,1);
}
.delete-i {
  color: rgba(252, 73, 32, 1);
  margin-left: 10px;
}
.pad {
  padding-top: 16px;
}
.addtext {
  color:rgba(24, 144, 255, 1);
}
.yao {
   min-height: 48px;
 }
 .line {
   border-bottom:1px dotted #999999;
   margin: 0 18px;
 }
 .common {
   padding: 16px 9px;
   margin: 0 18px;
   border-bottom:1px dotted #999999;
 }

</style>

<template>
  <div :class="{'ispand': !data.question.isExpand&&type===1}">
    <Expand v-if="data.question.isExpand&&type===1"
        :type="data.question.optionType"
        :no-all-name="data.question.noAllName"
        :multiple="data.type===2"
        :options="data.question.options"
        v-model="selectValue"
      />
    <Select
      :no-all-name="data.question.noAllName"
      v-if="!data.question.isExpand&&type===1"
      :error="data.error"
      :data="data"
      :disabled="!data.question.isEdit"
      :multiple="data.type===2"
      :options="data.question.options"
      v-model="selectValue"/>
    <div class="input" v-if="type===2">
       {{handerSelect(value)}}
    </div>
  </div>
</template>
<script>
import Expand from './Expand'
import Select from './Select'
export default {
  data() {
    return {
      selectValue: null
    }
  },
  computed:{

  },
  watch:{
    selectValue (val) {
      this.$emit('input', val)
    },
    value () {
      this.selectValue = this.value
    }
  },
  props: {
    data: {
      default: () => {},
      type: Object
    },
    value: {
      default: () => [],
      type: Array
    },
    type:{
      default: null,
      type: Number
    }
  },
  components: {
    Expand,
    Select
  },
  created() {
    this.selectValue = this.value
  },
  methods: {
    handerSelect(val) {
      if(!val) {
        return ''
      }
      val = val.map(i=>{
        return i.replace(/^\s+|\s+$/g, '')
      })
      let arr = []
      for(let i of this.data.question.options) {
        i.value = i.value.replace(/^\s+|\s+$/g, '')
        if(val.includes(i.value))arr.push(i.label)
      }
      return arr.join('；')

    },
  }
}
</script>
<style scoped>
.input{
  display: flex;
  align-items: center;
  color:rgba(51,51,51,1);

}
.ispand {
  background: #fff;
  border: 1px solid #EBEDF0;
}
</style>

<template>
  <div class="slider-container">
    <div class="slider-content">
      <p v-if="leftText">{{leftText}}</p>
      <p v-if="rightText">{{rightText}}</p>
    </div>
    <!-- 滑块容器 -->
    <div class="slider-wrapper" ref="sliderWrapper" @click.prevent="change">
      <!-- 数值 -->
      <div class="num left">{{min}}</div>
      <div class="num right">{{max}}</div>
      <!-- 填充块 -->
      <div class="slider-fill" ref="sliderFill"></div>
      <div class="slider-fill-back" ref="sliderFillBack"></div>
      <!-- 滑块 -->
      <div :class="{slider: true, start: drag === -1}"
           ref="slider"
           :style="{background: styleObj}"
           @touchend="touchend"
           @touchmove="move"
           @touchstart="changeDrag">
        <!--<p v-if="!drag" :style="{color: styleObj}" class="slider-text">{{percent}}{{unit}}</p>-->
        {{percent}}{{unit}}
      </div>
    </div>
    <div v-if="title" class="slider-title">
      {{title}}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    leftText: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    unit: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      disabled: true,
      slider: null,
      drag: -1,
      percent: null
    }
  },
  created () {
    const {value, min, max} = this
    if (value !== '' && value !== null && value !== undefined) {
      this.drag = 0
      if (value < min) {
        this.percent = min
      } else if (value > max) {
        this.percent = max
      } else {
        this.percent = value
      }
    } else {
      this.drag = -1
      // this.percent = min
    }
    this.$nextTick(() => {
      this.setValue()
    })
  },
  watch: {
    percent (val) {
      this.$emit('input', val)
    },
    value (val) {
      this.percent = val
    }
  },
  computed: {
    scale () {
      const {min, max} = this
      return 1 / (max - min)
    },
    styleObj () {
      const {percent, min, max} = this
      const colors = ['#06D3D3', '#10D2B3', '#19D296', '#8AD557', '#FAD819', '#FCC629', '#FFB43A', '#FE8D40', '#FD6D44', 'rgba(252, 73, 32, 1)']
      let index = Math.floor(10 * ((+percent - min) / (max - min))) - 1 < 0 ? 0 : Math.floor(10 * ((+percent - min) / (max - min))) - 1
      return colors[index]
    }
  },
  methods: {
    change (e) {
      if (this.disabled) {
        return false
      }
      const width = this.$refs.sliderWrapper.clientWidth
      const {min, max, precision, scale, step} = this
      let pageX = e.pageX
      let unit = Math.floor((width) * scale)
      let x = (max - min) / ((width) / (pageX - 49))
      const left = this.$refs.sliderWrapper.offsetLeft + 20
      let num = Math.round(x)
      let d = num * unit > width - 20 ? width - 20 : num * unit
      if (e.currentTarget === this.$refs.sliderWrapper) {
        if (pageX < left) {
          d = 0
          this.percent = min
          this.$refs.slider.style.left = '0px'
          this.$refs.sliderFill.style.width = '0px'
          this.$refs.sliderFillBack.style.width = '100%'
        } else if (pageX >= width + left - 40) {
          d = width - 10
          this.percent = max
          this.$refs.slider.style.left = width - 20 + 'px'
          this.$refs.sliderFill.style.width = width - 20 + 'px'
          this.$refs.sliderFillBack.style.width = '0px'
        } else {
          if (precision !== 0) {
            this.percent = +(min + x).toFixed(precision)
          } else {
            this.percent = Math.round(min + x)
          }
          if (step) {
            this.$refs.slider.style.left = d + 'px'
            this.$refs.sliderFill.style.width = d + 'px'
            this.$refs.sliderFillBack.style.width = width - d + 'px'
          } else {
            this.$refs.slider.style.left = pageX - left + 'px'
            this.$refs.sliderFill.style.width = pageX - left + 'px'
            this.$refs.sliderFillBack.style.width = width - pageX + left + 'px'
          }
        }
      }
    },
    setValue () {
      const {scale, min, percent, precision, max} = this
      const width = this.$refs.sliderWrapper.clientWidth
      if (percent) {
        let unit = Math.floor((width - 20) * scale)
        let x = percent - min
        let num = 0
        if (precision === 0) {
          num = +Math.round(x)
        } else {
          num = x
        }
        if (percent >= max) {
          this.$refs.slider.style.left = width - 20 + 'px'
          this.$refs.sliderFill.style.width = width - 20 + 'px'
          this.$refs.sliderFillBack.style.width = '0px'
        } else {
          this.$refs.slider.style.left = num * unit + 'px'
          this.$refs.sliderFill.style.width = num * unit + 'px'
          this.$refs.sliderFillBack.style.width = width - num * unit + 'px'
        }
      } else {
        this.$refs.slider.style.left = '0px'
        this.$refs.sliderFill.style.width = '0px'
        this.$refs.sliderFillBack.style.width = width + 'px'
      }
    },
    changeDrag (e) {
      if (this.disabled) {
        return false
      }
      this.drag = 1
      e.preventDefault()
    },
    touchend (e) {
      this.drag = 0
      if (this.disabled) {
        return false
      }
      const {scale, min, max, step, precision} = this
      const pageX = e.currentTarget.offsetLeft + 20
      const width = this.$refs.sliderWrapper.clientWidth
      let unit = Math.floor((width) * scale)
      let x = (max - min) / ((width) / (pageX - 20))
      let num = Math.round(x)
      let d = num * unit
      if (pageX < 20) {
        d = 0
        this.percent = min
      } else if (pageX >= (width - 20)) {
        d = width - 10
        this.percent = max
      } else {
        if (precision !== 0) {
          this.percent = +(min + x).toFixed(precision)
        } else {
          this.percent = Math.round(min + x)
        }
      }
      if (step) {
        this.$refs.slider.style.left = d + 'px'
        this.$refs.sliderFill.style.width = d + 'px'
        this.$refs.sliderFillBack.style.width = width - d + 'px'
      }
    },
    move (e) {
      const {drag, precision, max, min, disabled} = this
      if (disabled) {
        return false
      }
      if (drag === 1) {
        const width = this.$refs.sliderWrapper.clientWidth
        const pageX = Math.round(e.targetTouches[0].pageX)
        const left = this.$refs.sliderWrapper.offsetLeft + 20
        let x = (max - min) / ((width) / (pageX - left + 20))
        if (pageX >= (width + left - 40)) {
          this.$refs.slider.style.left = width - 20 + 'px'
          this.$refs.sliderFill.style.width = width - 20 + 'px'
          this.$refs.sliderFillBack.style.width = '0px'
          this.percent = max
        } else if (pageX < left) {
          this.$refs.slider.style.left = '0px'
          this.$refs.sliderFill.style.width = '0px'
          this.$refs.sliderFillBack.style.width = '100%'
          this.percent = min
        } else {
          this.$refs.slider.style.left = pageX - left + 'px'
          this.$refs.sliderFill.style.width = pageX - left + 'px'
          this.$refs.sliderFillBack.style.width = width - pageX + left + 'px'
          if (precision !== 0) {
            this.percent = +(min + x).toFixed(precision)
          } else {
            this.percent = Math.floor(min + x)
          }
        }
      }
    },
    init () {

    }
  }
}
</script>
<style scoped>
  .slider-container {
    padding: 10px 0;
    /*touch-action: none;*/
  }
  .slider-wrapper {
    position: relative;
    height: 6px;
    display: flex;
    touch-action: none;
    margin: 20px 30px 10px 30px;
    background:linear-gradient(90deg,rgba(24, 144, 255, 1) 0%,rgba(25,210,150,1) 26%,rgba(250,216,25,1) 51%,rgba(255, 172, 38, 1) 75%,rgba(251,43,78,1) 100%);
    border-radius:100px;
  }
  .slider-fill-back {
    background-color: #E9E9E9;
    height: 6px;
    width: 100%;
    display: inline-block;
    border-radius:100px;
  }
  .slider-fill {
    display: inline-block;
    width: 0;
    height:6px;
    background-color: transparent;
    border-radius:100px;
    touch-action: none;
  }
  .start {
    background: #E9E9E9 !important;
  }
  .slider {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    top: -10px;
    color: #fff;
    font-size: 12px;
    display: inline-block;
    cursor: pointer;
    touch-action: none;
    text-align: center;
  }
  /*.slider-arrow {*/
    /*width: 0;*/
    /*height: 0;*/
    /*border-width: 5px 5px 0;*/
    /*border-style: solid;*/
    /*bottom: -5px;*/
    /*left: 0px;*/
    /*position: absolute;*/
    /*touch-action: none;*/
  /*}*/
  .slider-text {
    position: absolute;
    top: -25px;
    left: -10px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 5px;
    display: inline-block;
    white-space: nowrap;
    touch-action: none;
  }
  .slider-title {
    text-align: center;
    font-size: 14px;
    touch-action: none;
  }
  .num {
    position: absolute;
    font-size: 14px;
    height: 12px;
    line-height: 12px;
    &.left {
      left: -25px;
      top: 0;
      text-align: left;
    }
    &.right {
      right: -25px;
      top: 0;
      text-align: right;
    }
  }
  .slider-content {
    display: flex;
    & p {
      flex: 1;
      margin: 0 10px;
      font-size: 12px;
      &:first-child {
        text-align: left;
        color: rgba(24, 144, 255, 1);
      }
      &:last-child {
        text-align: right;
        color: rgba(252, 73, 32, 1);
      }
    }
  }
</style>

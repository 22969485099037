<template>
  <div class="text-input-quetion">
    <div
      v-if="type===1 || type===2"
      hidefocus="true"
      :class="['formLine',{'isfocus': isfocus || value},{label: data.isShowAi&&data.question.isEdit},{'iserror': data.error},{'isEdit':type===2}]">
      <textarea
        style="flex: 1"
        @focus="focus"
        @blur="blur"
        class="text-input"
        v-if="+data.question.rows>1"
        :rows="data.question.rows"
        :placeholder="data.question.placeholder"
        v-model.trim="selectValue" />
        <input
        style="flex: 1"
        v-else
        @focus="focus"
        @blur="blur"
        class="text-input"
        :placeholder="data.question.placeholder"
        v-model.trim="selectValue" />
      <div
        v-if="data.question.postfix"
        class="unit">{{ data.question.postfix }}</div>
    </div>
    <div v-if="type===3" style="width: 100%;display:flex;align-items: center;">
      <textarea v-if="data.question.rows>1" class="readonly-input" readonly v-model="value" :rows="data.question.rows">
      </textarea>
      <div v-else>{{value}}</div>
      <div
        v-if="data.question.postfix"
        class="unit">{{ data.question.postfix }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isfocus: false,
      selectValue: null
    }
  },
  props: {
    type:{
      default: null,
      type: Number
    },
    value: {
      default: null,
      type: null
    },
    data: {
      default: () => {},
      type: Object
    }
  },

  components: {

  },
  watch:{
    selectValue (val) {
      this.$emit('input', val)
    },
    value () {
      this.selectValue = this.value
    },
  },
  created () {
    this.selectValue = this.value
  },
  methods: {
    focusInput () {
      this.$refs.number && this.$refs.number.$refs.number.focus()
    },
    focus () {
      this.isfocus = true
    },
    blur () {
      this.isfocus = false
    },
  }
}
</script>
<style scoped>
   .formLine{
    display: flex;
    min-height:44px;
    align-items: center;
    border-radius:4px;
    padding: 0 16px;
    &.isfocus {
      /* border:1px solid #3C82FF; */
    }
    & .input{
      flex: 1;
      height: 44px;
      color:rgba(51,51,51,1);
      background: #fff;
      &::placeholder {
        color: #999;
        -webkit-text-fill-color: #999;
        -webkit-opacity:1;
        opacity: 1;
      }
    }
     &.label{
      /* border: 1px solid #FFBC50; */
    }
    &.iserror {
      border:1px solid rgba(252, 73, 32, 1);
    }
   &.isEdit {
      background: #F4F4F4;
      & input {
        background: #F4F4F4;
      }
    }
  }
  .readonly-input{
   flex:1;
  }
  .text-input-quetion {
     width: 100%;

  }
  .unit{
    margin-left: 20px;
  }
</style>

<template>
<div>
  <div :class="['fix-text','text-left',{'type2-text': type===2}]">
    {{leftText}}
  </div>
  <div :class="['up','down',{'type2': type===2}]"/>
  <div v-if="has5.integer>1">
    <div v-for="(item,key) in has5.arr" :key="key" class="expand-slider slider2" :class="[key<1 ? 'line1' : 'line2']">
      <div v-for="el in item" :key="el" :class= "['item',{'bg':value>=+el}]" :style="{width:(1/item.length)*100 + '%'}">
       <div @click="item_click(el)" class="item-inner">
        {{el}}
      </div>
      </div>
    </div>
  </div>
   <div class="expand-slider one-line" v-if="has5.integer<2">
    <div v-for="i in max"  :key="i" :class= "['item',{'bg':value>=+i}]" :style="{width:(1/has5.all_num*100) + '%'}">
      <div @click="item_click(i)" v-if="i>=min" class="item-inner">
        {{i}}
      </div>
    </div>
  </div>
  <div class="position">
    <div :class="['up',{'type2': type===2}]"/>
  </div>

  <div :class="['fix-text','text-right',{'type2-text': type===2}]">{{rightText}}</div>
</div>
</template>
<script>
export default {
  data() {
    return {
      select_value: null
    }
  },
  props: {
    value: {
      type: [Number, Array],
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    disabled: {
      type: Boolean,
      default: false
    },
    leftText: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    type:{
      default: null,
      type: Number
    },
  },
  computed: {
    has5() {
      let integer = 0
      let remainder = null
      let half = null
      let all_num = this.min ? this.max-this.min + 1 : this.max-this.min
      integer=Math.ceil(all_num/5)
      remainder = all_num%2
      half = remainder===0 ? all_num / 2 : all_num / 2 + 1
      let arr = [[],[]]

      if(integer>1) {
        let min = this.min ? this.min : 1
        let index = 1
        while(min<=this.max){
          if(index<=half) {
            arr[0].push(min)
          } else {
            arr[1].push(min)
          }
          min ++
          index ++
        }
      }
      return {
        integer,
        arr,
        all_num
      }
    }
  },
  methods: {
    item_click (i) {
      if(this.disabled) return
      if(this.select_value === i) {
        i = 0
      }
      this.select_value = i
      this.$emit('input', i)
    }
  }
}
</script>
<style scoped>
.expand-slider {
  display: flex;
  flex-wrap: wrap;
  & .item {
     background: rgba(245,248,255,1);
     &.bg{
       background: transparent;
     }
    & .item-inner {
      height:48px;
      line-height: 48px;
      text-align: center;
      font-size:14px;
      font-weight:400;
      color:rgba(102,102,102,1);
    }
  }
}
.slider2 {
  margin-bottom: 4px;
}
.one-line {
   background:linear-gradient(90deg,rgba(6,211,211,1) 0%,rgba(25,210,150,1) 26%,rgba(250,216,25,1) 51%,rgba(255,180,58,1) 75%,rgba(251,43,78,1) 100%);
}
.line1 {
  background:linear-gradient(90deg,rgba(6,211,211,1) 0%,rgba(16,210,180,1) 33%,rgba(156,213,77,1) 67%,rgba(201,215,52,1) 100%);
}
.line2 {
  background:linear-gradient(90deg,rgba(201,215,52,1) 0%,rgba(250,216,25,1) 34%,rgba(255,180,58,1) 60%,rgba(251,43,78,1) 100%)
}
.fix-text {
  font-size:16px;
  font-weight:400;
  line-height:40px;
}
.text-right {
  display: flex;
  justify-content: flex-end;
  color:rgba(251,43,78,1)
}
.text-left {
  text-align: left;
  color:rgba(6,211,211,1);
}
.type2-text {
  color:#999999;
}
.up{
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #000000;
  font-size: 0;
  line-height: 0;
  &.down {
     transform: rotate(180deg);
     margin-left: 10px;
     margin-bottom: 4px;
  }
  &.type2{
     border-bottom: 7px solid #999999;
  }
}
.position {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  margin-top: 4px;
}

</style>

<template>
  <div
    class="multiple">
    <ul
      ref="select"
       @click="showselect"
      :class="['select-input',{'isfocus': isfocus || value},{label: data.isShowAi},{'iserror': error},{isEdit: disabled}]">
      <div class="value">{{valueStr}}</div>
      <input
        @focus="focus"
        @blur="blur"
        class="selectContent" disabled/>
      <li
        v-if="!disabled"
        class="show-icon">
        <img width="16" height="16" :class="['down', {up: isup}]" src="../../../assets/img/arrow-item.png"/>
      </li>
    </ul>
     <MulSelect
      :no-all-name="noAllName"
      :multiple="multiple"
      :visible.sync="selectToast"
      :options="options"
      :value="value"
      :title="title"
      @comfire="comfire"
      v-if="selectToast"/>
  </div>
</template>
<script>
import MulSelect from './MultiSelect'

export default {
  data () {
    return {
      selectToast: false,
      isup: false,
      isfocus: false
    }
  },
  props: {
    multiple: {
      default: false,
      type: Boolean
    },
    noAllName: {
      type: String,
      default: ''
    },
    options: {
      default: () => [],
      type: Array
    },
    value: {
      default: () => [],
      type: Array
    },
    error: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    data: {
      default: () => {},
      type: Object
    },
    title: {
      default: '',
      type: String
    }
  },
  computed: {
    valueStr () {
      let arr = []
      let val = this.value ? this.value : []
      for(let i of this.options) {
        for(let el of val) {
          if(el === i.value) {
            arr.push(i.label)
          }
        }

      }
      return arr.join('；')
    },
  },
  created () {
    console.log(33)

  },
  watch: {
    selectToast (val) {
      this.isup = val
    }
  },
  components: {
    MulSelect
  },
  methods: {
    comfire (val) {
      this.$emit('input', val)
    },
    showselect () {
      if (this.disabled) {
        return
      }
      this.selectToast = true
      this.isup = !this.isup
    },
    focus () {
      this.isfocus = true
    },
    blur () {
      this.isfocus = false
    }
  }
}
</script>
<style scoped>
/* ios兼容 */
input[disabled],input:disabled,input.disabled{
  color: #666;
  -webkit-text-fill-color: #666;
  -webkit-opacity:1;
  opacity: 1;
}
.multiple {
  & .select-input {
    display: flex;
    min-height: 44px;
    align-items: center;
    position: relative;
    border-radius:4px;
    padding: 0 12px;
    &.isfocus {
      /* border:1px solid #3C82FF; */
    }
    &.label{
      /* border: 1px solid #FFBC50; */
    }
    &.iserror {
      border:1px solid rgba(252, 73, 32, 1);
    }
    &.isEdit {
      background: #F4F4F4;
      & input {
        background: #F4F4F4;
      }
    }
    & .selectContent {
      position: absolute;
      left:0;
      width: 100%;
      height: 100%;
      background: transparent;
    }
    & .show-icon {
      width: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.value {
  width: 100%;
  height: 100%;
  margin-right:10px;
  &.grey9{
    color:#999;
  }
}
.down {
    &.up{
      transform: rotate(90deg);
    }
  }
</style>

<template>
  <div class="text-input-quetion">
     <div v-if="type===1 || type===2" :class="['formLine',{'isfocus': value},{label: data.isShowAi&&data.question.isEdit},{'iserror': data.error},{'isEdit':type===2}]">
      <div
        @click="openPicker"
        class="timePicker">
        <input
          disabled
          class="input"
          :value="hander_time()" />
        <img
          v-if="data.question.isEdit"
          style="width:20px;height:20px; "
          src="../../../assets/img/new-img/time.png">
      </div>
      <!-- <calendar
        v-model="isshow"
        :default-date="selectValue ? new Date(+selectValue) : null"
        @change="handleConfirm"/> -->
    </div>
    <van-popup
      v-model="show"
      position="bottom"
      :style="{ height: '50%' }"
    >
    <van-datetime-picker
      v-model="selectValue"
      :type="data.question.time_type ? 'datetime' : 'date'"
      title="选择时间"
      @confirm="handleConfirm"
      :min-date="minDate"
      :max-date="maxDate"
    />
    </van-popup>
    <div v-if="type===3">{{hander_time()}}</div>
  </div>
</template>
<script>
// import Calendar from '../../../../components/Calendar'
import { formatDate,formatDateMinute } from '../../../utils/format.js'
export default {
  data () {
    return {
      isfocus: false,
      show: false,
      selectValue: null,
      minDate: new Date('1900/1/1'),
      maxDate: new Date()
    }
  },
  props: {
    type:{
      default: null,
      type: Number
    },
    value: {
      default: null,
      type: null
    },
    data: {
      default: () => {},
      type: Object
    }
  },

  components: {
    // Calendar
  },
  watch:{
    value () {
      this.selectValue = this.value ? new Date(+this.value) : new Date()
    },
  },
  created () {
    this.selectValue = this.value ? new Date(+this.value) : new Date()
  },
  methods: {
    focusInput () {
      this.$refs.number && this.$refs.number.$refs.number.focus()
    },
    hander_time() {
      if(!this.value) return null
      return this.data.question.time_type ? formatDateMinute(this.value) : formatDate(this.value)
    },
    focus () {
      this.isfocus = true
    },
    blur () {
      this.isfocus = false
    },
    handleConfirm (val) {
      this.$emit('input', new Date(val).getTime())
      this.show = false
    },
    // 时间修改
    formatDate,
    formatDateMinute,
    openPicker() {
      if(this.type === 2)return
      this.show = true
    }
  }
}
</script>
<style scoped>
input[disabled],input:disabled,input.disabled{
    color: #333;
    -webkit-text-fill-color: #333;
    -webkit-opacity:1;
    opacity: 1;
  }
   .formLine{
    display: flex;
    min-height:44px;
    align-items: center;
    border-radius:4px;
    padding: 0 16px;
    &.isfocus {
      /* border:1px solid #3C82FF; */
    }
    & .input{
      flex: 1;
      height: 44px;
      color:rgba(51,51,51,1);
      background: #fff;
      &::placeholder {
        color: #999;
        -webkit-text-fill-color: #999;
        -webkit-opacity:1;
        opacity: 1;
      }
    }
     &.label{
      /* border: 1px solid #FFBC50; */
    }
    &.iserror {
      border:1px solid rgba(252, 73, 32, 1);
    }
    &.isEdit {
      background: #F4F4F4;
      & input {
        background: #F4F4F4;
      }
    }
    & .timePicker{
      display: flex;
      flex: 1;
      align-items: center;
      color: #666;
    }
  }
  .readonly-input{
    width: 100%;
  }
  .text-input-quetion {
     width: 100%;
  }
  .unit{
    margin-left: 20px;
  }
</style>

<template>
  <div>
    <div class="blood-press" v-if="type===1 || type===2">
      <div :class="['formLine12',{'isfocus': isfocus || value},{label: data.isShowAi&&data.question.isEdit},{'iserror': (value&&!selectValue1 || !value)&&data.error},{'isEdit':type===2}]">
        <NumberInput
        @numfocus="focus"
        @numblur="blur"
        :disabled="type===2"
        :integerDigits="4"
        ref="number"
        v-model="selectValue1"
        placeholder="舒张压"
        class="input"/>
        mmHg
      </div>
      <div class="line">/</div>
      <div :class="['formLine12',{'isfocus': isfocus || value},{label: data.isShowAi&&data.question.isEdit},{'iserror': (value&&!selectValue2 || !value)&&data.error},{'isEdit':type===2}]">
        <NumberInput
        :disabled="type===2"
        @numfocus="focus"
        :integerDigits="4"
        @numblur="blur"
        ref="number"
        placeholder="收缩压"
        v-model="selectValue2"
        class="input"/>
        mmHg
      </div>
   </div>
   <div v-else>{{selectValue1}} / {{selectValue2}}（mmHg)</div>
  </div>
</template>
<script>
import NumberInput from '../../../components/NumberInput'
export default {
  data() {
    return{
      isfocus: false,
      selectValue1: null,
      selectValue2: null
    }
  },
  props: {
    type:{
      default: null,
      type: Number
    },
    value: {
      default: null,
      type: null
    },
    data: {
      default: () => {},
      type: Object
    }
  },
  components: {
    NumberInput
  },
  watch:{
    selectValue1 (val) {
      let value = !val&&!this.selectValue2 ? null : [val&&+val,this.selectValue2&&+this.selectValue2]
      this.$emit('input', value)
    },
    selectValue2 (val) {
      let value = !val&&!this.selectValue1 ? null : [this.selectValue1&&+this.selectValue1,val&&+val]
      this.$emit('input', value)
    },
    value () {
      if(this.value) {
        this.selectValue1 = this.value[0] || null
        this.selectValue2 = this.value[1] || null
      }
    },
  },
  created() {
    if(this.value) {
      this.selectValue1 = this.value[0] || null
      this.selectValue2 = this.value[1] || null
    }
  },
  methods: {
    focus () {
      this.isfocus = true
    },
    blur () {
      this.isfocus = false
    },
  }
}
</script>
<style scoped>
.blood-press {
    width: 100%;
    display: flex;
    align-items: center;
    & .input {
      width: 100%;
    }
  }
.formLine12{
  display: flex;
  min-height:44px;
  align-items: center;
  border-radius:4px;
  padding: 0 12px;
  background: #fff;
  border: 1px solid #EBEDF0;
  &.isfocus {
    /* border:1px solid #3C82FF; */
  }
  & .input{
    flex: 1;
    height: 44px;
    color:rgba(51,51,51,1);
    background: #fff;
    &::placeholder {
      color: #999;
      -webkit-text-fill-color: #999;
      -webkit-opacity:1;
      opacity: 1;
    }
  }
    &.label{
    /* border: 1px solid #FFBC50; */
  }
  &.iserror {
    border:1px solid rgba(252, 73, 32, 1);
  }
  &.isEdit {
    background: #F4F4F4;
    & .input{
      background: #F4F4F4;
    }
  }
}
.show {
  font-size: 16px;
  color:rgba(51,51,51,1);
}
.line {
  padding: 0 12px;
  color: #242938;
}
</style>

<template>
  <div class="slider-question">
      <Slider
        class="slider-center"
        v-if="type===1 && !data.question.isExpand"
        :show-tooltip="true"
        show-input
        :leftText="data.question.prefix"
        :rightText="data.question.postfix"
        :disabled="!data.question.isEdit"
        :min="data.question.options[0]"
        :max="data.question.options[1]"
        v-model="selectValue"/>
      <SliderShow
       class="slider-center"
       v-if="type===2 && !data.question.isExpand"
       :step="false"
        v-model="selectValue"
        :min="data.question.options[0]"
        :max="data.question.options[1]"
        :leftText="data.question.prefix"
        :rightText="data.question.postfix"
      />
      <ScaleExpan
        :type="type"
        v-model="selectValue"
        :min="data.question.options[0]"
        :max="data.question.options[1]"
        class="slider-center"
        :disabled="type===2"
        v-if="data.question.isExpand"
        :leftText="data.question.prefix"
        :rightText="data.question.postfix"/>
  </div>
</template>
<script>
import Slider from '../../../components/Slider'
import SliderShow from '../../../components/CircleSlider'
import ScaleExpan from './ScaleExpan'
export default {
  data(){
    return {
      selectValue: null
    }
  },
  props: {
    type:{
      default: null,
      type: Number
    },
    value: {
      default: null,
      type: null
    },
    data: {
      default: () => {},
      type: Object
    }
  },
  computed: {

  },
  watch: {
    selectValue (val) {
      this.$emit('input', val)
    }
  },
  components: {
    SliderShow,
    Slider,
    ScaleExpan
  },
  created() {
    this.selectValue = this.value
  },
  methods: {

  }
}
</script>
<style scoped>
.slider-question {
  width: 100%;
}
.fix-text {
  font-size:14px;
  font-weight:400;
  color:rgba(60,63,65,1);
  line-height:24px;
  &.text-left {
    display: flex;
    justify-content: flex-end;
  }
}
</style>

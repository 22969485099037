<template>
  <div class="select-multi">
    <ul class="select-wrap">
      <li v-for="(i, index) in arr"
          :key="index"
          @click="change(i)"
          :style="{width: `${itemLen}%`}"
          class="select-li"
          >
          <div :class="['select-item', i.checkFlag ? 'check' : '']">
            {{ i.label }}
          <img v-if="multiple && i.checkFlag" class="select-icon" src="../../../assets/img/select.png" >
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data () {
    return {
      arr: []
    }
  },
  props: {
    multiple: {
      default: false,
      type: Boolean
    },
    noAllName: {
      type: String,
      default: ''
    },
    options: {
      default: () => [],
      type: Array
    },
    value: {
      default: () => [],
      type: Array
    },
    type:{
      default:1,
      type: Number
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    itemLen(){
      let type = this.type
      if(type === 4){
        return 25
      }else if(type === 3){
        return 33.3
      }else if(type === 2){
        return 50
      }else {
        return 100
      }
    }
  },
  watch: {
    options () {
      this.arr = this.options.map((obj) => {
        let checkFlag = false
        if (this.value && this.value.length > 0) {
          for(let item of this.value) {
            if (item === obj.value) {
              checkFlag = true
              break
            }
          }
        }
        return {
          ...obj,
          checkFlag: checkFlag
        }
      })
    }
  },
  created () {
    this.arr = this.options.map((obj) => {
      let checkFlag = false
      if (this.value && this.value.length > 0) {
        for(let item of this.value) {
          if (item === obj.value) {
            checkFlag = true
            break
          }
        }
      }
      return {
        ...obj,
        checkFlag: checkFlag
      }
    })
  },
  methods: {
    comfire () {
      let arr = []
      this.arr.forEach( i => {
        if (i.checkFlag) {
          arr.push(i.value)
        }
      })
      this.$emit('input', arr)
    },
    change (obj) {
      const {noAllName} = this
      if(this.multiple) {
        console.log(obj.value)
        if (obj.value === (noAllName ? noAllName : '以上都不是') || obj.value === '不清楚') {
          this.arr = this.arr.map((item) => {
            if (item.value === obj.value) {
              item.checkFlag = true
            } else {
              item.checkFlag = false
            }
            return item
          })
        } else {
          obj.checkFlag = !obj.checkFlag
          this.arr = this.arr.map((item) => {
            if (item.value === (noAllName ? noAllName : '以上都不是') || item.value === '不清楚') {
              item.checkFlag = false
            }
            return item
          })
        }

      } else {
        this.arr = this.arr.map((item) => {
          if (item.value === obj.value) {
            item.checkFlag = !item.checkFlag
          } else {
            item.checkFlag = false
          }
          return item
        })
      }
      this.comfire()
    }
  }
}
</script>
<style scoped>
.select-wrap{
  flex-wrap:wrap;
  align-content: flex-start;
  display: flex;
  line-height: 18px;
  font-size: 14px;
  color: #242938;
  align-items: center;
  margin:-4px;
  text-align: center;
  & .select-li{
    padding: 4px;
    & .select-item{
      padding: 12px 2px;
      background: #FFFFFF;
      border: 1px solid #EBEDF0;
      border-radius: 4px;
      position: relative;
      &.check{
        background: #3C82FF;
        color:#fff;
        background: rgba(24, 144, 255, 1);
      }
      & .select-icon{
        width:24px;
        height:24px;
        position: absolute;
        right: 0;
        top:0;
      }
    }
  }
}
</style>

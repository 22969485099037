<template>
  <div class="step-container">
    <template>
      <div :class="{btn: true, disabled: minusDisable || disabled }" @click="minus">－</div>
      <input :class="['input',{disabled: disabled}]" v-model="val" :readonly="!canEdit" />
      <div :class="{btn: true, disabled: plusDisable || disabled}" @click="plus">＋</div>
      <p class="unit">{{unit}}</p>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    unit: {
      type: String,
      default: '次'
    },
    step: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 30
    },
    canEdit: { // 是否可以直接输入数字，默认为true
      type: Boolean,
      default: false
    },
    disabled: { // 组件是否可以编辑
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      val: 0
    }
  },
  created () {
    this.val = this.value
  },
  computed: {
    minusDisable () {
      const {val, min} = this
      return val <= min
    },
    plusDisable () {
      const {val, max} = this
      return val >= max
    }
  },
  watch: {
    val (val) {
      this.$emit('input', val)
    },
    value (val) {
      this.val = val
    }
  },
  methods: {
    minus () {
      const {val, step, min} = this
      if (val <= min) {
        return false
      }
      this.val -= step
    },
    plus () {
      const {val, step, max} = this
      if (val >= max) {
        return false
      }
      this.val += step
    }
  }
}
</script>
<style scoped>
  .step-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 16px 6px; */
  }
  .btn {
    width: 44px;
    height: 44px;
    background-color: #3C82FF;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 0px;
    &.disabled {
      background-color: rgba(241, 243, 244, 1);
      color: #9C9C9C;
    }
  }
  .input {
    width: 48px;
    height: 44px;
    text-align: center;
    border: 1px solid #3C82FF;
    border-radius: 4px;
    display: inline-block;
    margin: 0 4px;
    &.disabled {
      background-color: #fff;
      border: 1px solid #F1F3F4;
      color: rgba(156, 156, 156, 1);
    }
  }
  .unit {
    margin-left: 20px;
    color: #333;
    font-size: 16px;
  }
  .show {
    font-size: 16px;
    color: #333;
  }
</style>

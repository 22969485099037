<template>
  <div>
    <div :class="['xianshi',{'is-disabled': disabled}]" @click="signIn">
      <img
        v-if="value"
        class="signIcon"
        :src="getImgURL(value)">
      <div
        class="placeH"
        v-else>点击签名</div>
    </div>
    <Sign
      v-if="toast"
      :url='value'
      :toast.sync="toast"
      @saveSign="saveSign"
    />
  </div>
</template>
<script>
import Sign from './Sign'
import { getImgURL } from '../../../utils/imgUrl'
export default {
  data () {
    return {
      toast: false
    }
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      default: null,
      type: null
    }
  },
  components: {
    Sign
  },
  methods: {
    signIn () {
      if (this.disabled) return
      this.toast = true
    },
    saveSign (val) {
      this.$emit('input', val.png)
    },
    getImgURL
  },
  created () {

  }
}
</script>
<style scoped>
  .xianshi {
      height: 140px;
      background:#fff;
      border-radius:4px;
      display: flex;
      justify-content: center;
      align-content: center;
    }
  .placeH{
    line-height: 140px;
    font-size: 14px;
    color: #1890FF;
    &:before {
      content: "";
      display: inline-block;
      background: url("../../../assets/img/new-img/sign.png") center center no-repeat;
      background-size: 100% 100%;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      transform: translateY(3px);
    }
  }
  .signIcon {
    max-height: 100%;
    max-width: 100%;
  }
  .is-disabled {
    background: #F4F4F4;
    & .placeH {
      color: #333;
    }
  }
</style>

<template>
  <div class="text-input-quetion">
    <div
      @click="focusInput"
      v-if="type===1 || type===2"
      :class="['formLine',{'isfocus': isfocus || value},{label: data.isShowAi&&data.question.isEdit},{'iserror': data.error},{'isEdit':type===2}]">
      <NumberInput
        @numfocus="focus"
        @numblur="blur"
        ref="number"
        :disabled="type===2"
        :placeholder="data.question.placeholder"
        class="input"
        v-model="selectValue"/>
        <div
        v-if="data.question.postfix"
        class="unit">{{ data.question.postfix }}</div>
    </div>
    <div v-if="type===3">
      <span v-if="value">{{value}}</span>
      <span
        v-if="data.question.postfix"
        class="unit">{{ data.question.postfix }}</span>
      </div>
  </div>
</template>
<script>
import NumberInput from '../../../components/NumberInput'
export default {
  data () {
    return {
      isfocus: false,
      selectValue: null
    }
  },
  props: {
    type:{
      default: null,
      type: Number
    },
    value: {
      default: null,
      type: null
    },
    data: {
      default: () => {},
      type: Object
    }
  },

  components: {
    NumberInput
  },
  watch:{
    selectValue (val) {
      this.$emit('input', val)
    },
    value () {
      this.selectValue = this.value
    },
  },
  created () {
    this.selectValue = this.value
  },
  methods: {
    focusInput () {
      this.$refs.number && this.$refs.number.$refs.number.focus()
    },
    focus () {
      this.isfocus = true
    },
    blur () {
      this.isfocus = false
    },
  }
}
</script>
<style scoped>
   .formLine{
    display: flex;
    min-height:44px;
    align-items: center;
    border-radius:4px;
    padding: 0 16px;
    &.isfocus {
      /* border:1px solid #3C82FF; */
    }
    & .input{
      flex: 1;
      height: 44px;
      color:rgba(51,51,51,1);
      background: #fff;
      &::placeholder {
        color: #999;
        -webkit-text-fill-color: #999;
        -webkit-opacity:1;
        opacity: 1;
      }
    }
     &.label{
      /* border: 1px solid #FFBC50; */
    }
    &.iserror {
      border:1px solid rgba(252, 73, 32, 1);
    }
    &.isEdit {
      background: #F4F4F4;
      & input {
        background: #F4F4F4;
      }
    }
  }
  .text-input-quetion {
    width: 100%;
  }
.unit {
  margin-left: 0.20833rem;
}
</style>

<template>
  <div class="pictures">
    <div class="photo" v-for="(i, key) in value" :key="key">
      <div class="inner">
        <img @click="selectItem(key)" :src="getImgURL(i)" />
      </div>
    </div>
    <div
      class="photo"
      v-if="!disabled && (!value || (value && value.length < 9))"
    >
      <van-uploader :after-read="takePhoto" />
    </div>
  </div>
</template>
<script>
import { getImgURL } from "../../../utils/imgUrl"
import { mapMutations } from "vuex"

export default {
  data() {
    return {}
  },
  props: {
    item: {
      default: null,
      type: null,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    value() {
      return this.item.value
    },
  },
  methods: {
    ...mapMutations("takePhoto", ["setCurrentItem", "setCurrentImgIndex"]),
    takePhoto(file) {
      if ((this.value && this.value.length === 9) || this.disabled) {
        return
      }
      this.item.value = this.item.value ? this.item.value : []
      this.item.value.push(file.file)
    },
    selectItem(key) {
      this.$emit("savePosition")
      this.setCurrentItem(this.item)
      this.setCurrentImgIndex(key)
      this.$router.push({
        path: "/gauge-magnify",
        query: {
          disabled: this.disabled,
        },
      })
    },
    getImgURL,
  },
}
</script>
<style scoped>
.pictures {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  & .photo {
    width: 33.3%;
    height: 102px;
    border-radius: 4px;
    padding: 4px;
    & .inner {
      overflow: hidden;
      object-fit: cover;
      background: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & img {
      max-width: 100%;
      object-fit: cover;
      &.click-img {
        background: rgba(244, 248, 255, 1);
      }
    }
  }
}
.close-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  right: 0;
}
</style>

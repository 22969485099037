<template>
  <div style="background: #fff;border-radius: 4px;">
    <div
      v-if="!data.question.isExpand"
      @click="htmlLink(data.frontend_id)"
      class="linkBox">
      <div class="link">{{ data.question.urlLabel}}</div>
      <img width="16px;" src="../../../assets/img/new-img/link.png"/>
    </div>
    <RichText style="padding:12px 16px;" v-else :html="data.question.html" />
  </div>
</template>
<script>
import RichText from '../../../components/RichText'
export default {
  data() {
    return {

    }
  },
  props: {
    data: {
      default: () => {},
      type: Object
    }
  },
  components: {
    RichText
  },
  methods: {
    saveCheckposi() {
      this.$emit('savePosition')
    },
    htmlLink (id) {
      this.saveCheckposi()
      this.$router.push({
        path: '/gauge-html',
        query: {
          id,
          title: this.$route.query.title
        }
      })
    },
  }
}
</script>
<style>
  .add-html-box{
    & img {
      max-width: 100%;
    }
  }
</style>
<style scoped>
   .linkBox {
    display: flex;
    align-items: center;
    min-height: 44px;
    background: #fff;
    color: #1890FF;
    border-radius:4px;
    padding:12px 16px;
    & .link{
      color: #1890FF;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>

<template>
  <div
    v-if="visible"
    class="out">
    <div class="bg" @click="close"></div>
    <div
      class="content">
      <ul class="the-icon">
        <li class="button" style = "text-align: center;font-size: 16px;color: #323233; flex: 1;">{{title}}</li>
        <li
          class="button"
          style = "float: right;"
        >
        <img @click="close" width="24" src="../../../assets/img/new-img/closs.png"/>
        </li>
      </ul>
      <div class="selects">
        <ul
         v-for="(i, index) in arr"
         :key="index"
         @click="change(i)"
         :class="['check-title', i.checkFlag ? 'check' : '']">
            <li class="list label">{{ i.label }}</li>
            <li
              class="list"
              style="width: 18px;"
            >
              <img
                v-if="i.checkFlag"
                class="the-img"
                src="../../../assets/img/icon-mulselect.png">
            </li>
        </ul>
      </div>
      <div
        v-if="multiple"
        class ="btn"
        @click="comfire">确定</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      arr: []
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    noAllName: {
      type: String,
      default: ''
    },
    multiple: {
      default: false,
      type: Boolean
    },
    options: {
      default: () => [],
      type: Array
    },
    value: {
      default: () => [],
      type: Array
    },
    title: {
      default: '',
      type: String
    }
  },
  computed: {
  },
  created () {
    this.arr = this.options.map((obj) => {
      let checkFlag = false
      if (this.value && this.value.length > 0) {
        for(let item of this.value) {
          if (item === obj.value) {
            checkFlag = true
            break
          }
        }
      }
      return {
        ...obj,
        checkFlag: checkFlag
      }
    })
  },
  mounted () {

  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    comfire () {
      let arr = []
      this.arr.forEach( i => {
        if (i.checkFlag) {
          arr.push(i.value)
        }
      })
      this.$emit('comfire', arr)
      this.close()
    },
    change (obj) {
      const {noAllName} = this
      if(this.multiple) {
        console.log(obj.value)
        if (obj.value === (noAllName ? noAllName : '以上都不是') || obj.value === '不清楚') {
          this.arr = this.arr.map((item) => {
            if (item.value === obj.value) {
              item.checkFlag = true
            } else {
              item.checkFlag = false
            }
            return item
          })
        } else {
          obj.checkFlag = !obj.checkFlag
          this.arr = this.arr.map((item) => {
            if (item.value === (noAllName ? noAllName : '以上都不是') || item.value === '不清楚') {
              item.checkFlag = false
            }
            return item
          })
        }

      } else {
        this.arr = this.arr.map((item) => {
          if (item.value === obj.value) {
            item.checkFlag = !item.checkFlag
          } else {
            item.checkFlag = false
          }
          return item
        })
        this.comfire()
      }
    }

  }
}
</script>
<style scoped>
.out {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transition: all .3s ease-in-out;
  & .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 0.5);
  }
  & .content {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 60%;
    border-radius: 4px;
    border-radius: 20px 20px 0px 0px;
    padding-bottom: 15px;
    & .the-icon {
      display: flex;
      height: 56px;
      line-height: 56px;
      align-items: center;
      text-align: center;
      padding: 0 18px;
      font-size: 15px;
      & .button {
        & .default {
          cursor: pointer;
          font-size:16px;
          font-weight:bold;
          color:rgba(153,153,153,1);
          &.confirm {
            color:rgba(60,130,255,1);
            float: right;
          }
        }
      }
    }
    & .selects {
      flex: 1;
      overflow: auto;
    }
  }
}
.the-img {
  width: 16px;
  height: 16px;
}
.check-title {
  display: flex;
  font-size:14px;
  font-weight:400;
  color:rgba(51,51,51,1);
  padding: 14px 20px;
  &.check{
    color: rgba(24, 144, 255, 1);
  }
}
.list {
  display: flex;
  align-items: center;
}
.label {
  flex: 1;
}
.btn {
  height: 40px;
  background: #1890FF;
  border-radius: 20px;
  text-align: center;
  color: #FFFFFF;
  line-height: 40px;
  margin:0 16px 13px;
}
</style>
